import React from "react";

import ClearIcon from "@mui/icons-material/Clear";

import CheckedList from "./CheckedList";
// import type { ConstantCheckedList } from "types/misc";

const Ulemper: React.FC<{
	title: string;
	content: string[];
}> = ({ title, content }) => {
	return (
		<CheckedList
			title={title}
			content={content}
			icon={<ClearIcon color="red" />}
		/>
	);
};

export default Ulemper;
