import { Rating05 } from "./misc";

export interface ITitleAvatar {
	title: string;
	avatarSrc: string;
}

export function isTitleAvatar(obj: unknown): obj is ITitleAvatar {
	return (
		typeof obj === "object" &&
		obj !== null &&
		"title" in obj &&
		typeof obj["title"] === "string" &&
		"avatarSrc" in obj &&
		typeof obj["avatarSrc"] === "string"
	);
}

export type AndmeldelseContent = {
	FordeleTitle: string;
	FordeleContent: string[];
	UlemperTitle: string;
	UlemperContent: string[];
	BonusTitle: string;
	BonusHjemmeside: string;
	BonusLink: string;
	expandableContent: string;
	AntalStjerner: Rating05;
	SideNavn: string;
};
