type ApiEnedpoints = {
	getAnmeldelser: string;
	getTitleAndAvatar: string;
	getTitles: string;
	getAvatars: string;
	getBonusPages: string;
};

export const ApiEndpoints: ApiEnedpoints = {
	getAnmeldelser: "https://bonuslake.dk/api/anmeldelser/get_anmeldelser.php",
	getTitleAndAvatar: "https://bonuslake.dk/api/get_title_avatar.php",
	getTitles: "https://bonuslake.dk/api/get_title_avatar.php?o=1",
	getAvatars: "https://bonuslake.dk/api/get_title_avatar.php?o=2",
	getBonusPages: "https://bonuslake.dk/api/get_list.php?get_list",
};

export const regexNewline = "(\r\n|\r|\n)"; // OLD: "[\r\n]+"

export const regexInsideSquareBrackets = `(\[.*?\])`;

export const regexStrings = {
	NewLineTabRepeating: `(\r\n{1,}|\r{1,}|\n{1,}|\t{1,})`,
	NewLine: `(\r\n|\r|\n)`,
	InsideSquareBrackets: `(\[.*?\])`,
	InsideParentheses: `(\(.*?\))`,
	InsideCurlyBrackets: `(\{.*?\})`,
	InsideAngleBrackets: `(<.*?>)`,
	InsideDoubleQuotes: `(\".*?\")`,
	InsideSingleQuotes: `(\'.*?\')`,
};
