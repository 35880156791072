import React from "react";

import Box from "@mui/system/Box";

type CenterRowProps = {
	children?: React.ReactNode;
};

const CenterRow: React.FC<CenterRowProps> = (props) => {
	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
			}}
		>
			<Box
				sx={{
					paddingLeft: "1rem",
					paddingTop: "1rem",
					paddingBottom: "1rem",
					maxWidth: "75vw",
				}}
			>
				{props.children}
			</Box>
		</Box>
	);
};

export default CenterRow;
