import React from "react";

import CheckedList from "./CheckedList";

import type { ConstantCheckedList } from "types/misc";

const Fordele: React.FC<ConstantCheckedList> = ({ title, content }) => {
	return <CheckedList title={title} content={content} color="green" />;
};

export default Fordele;
