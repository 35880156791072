import React from "react";
import { Paper, Typography, Button, Link, Box } from "@mui/material";
import { Fordele, Ulemper } from "./parts";

import { Link as RouterLink } from "react-router-dom";

// type GeneratedAnmeldelseContent = {
// 	FordeleTitle: string;
// 	FordeleContent: string[];
// 	UlemperTitle: string;
// 	UlemperContent: string[];
// 	BonusTitle: string;
// 	BonusHjemmeside?: string;
// 	BonusLink?: string;
// };

export const GenerateContent = (
	FordeleTitle: string,
	FordeleContent: string[],
	UlemperTitle: string,
	UlemperContent: string[],
	BonusTitle: string,
	BonusHjemmeside: string,
	BonusLink: string
): React.JSX.Element[] => {
	const spacerStyle = {
		flex: `1 0`,
	};
	return [
		<Fordele
			key={`F${BonusTitle}`}
			title={FordeleTitle}
			content={FordeleContent}
		/>,

		<Ulemper
			key={`U${BonusTitle}`}
			title={UlemperTitle}
			content={UlemperContent}
		/>,

		<Paper
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				alignContent: "space-around",
				justifyContent: "space-around",
				rowGap: "0.5rem",
				bgcolor: "primary.main",
				padding: "1rem",
			}}
			elevation={1}
			key={`B${BonusTitle}`}
		>
			<Typography textAlign={"center"} variant="body1">
				Velkomstbonus
			</Typography>
			<Box sx={spacerStyle} />

			<Typography textAlign={"center"} variant="h5">
				{BonusTitle}
			</Typography>
			<Box sx={spacerStyle} />
			<Button
				// onClick={() => window.open(BonusLink, "_blank")}
				color="lime"
				variant="contained"
				component={RouterLink}
				to={BonusLink}
			>
				Modtag Bonus
			</Button>
			<Box sx={spacerStyle} />

			<Link
				textAlign={"center"}
				display={"block"}
				component={RouterLink}
				to={BonusHjemmeside}
				variant="h6"
			>
				Gå til hjemmeside
			</Link>
			<Typography align="center" color="#5A5A5A" variant="caption">
				REKLAME
			</Typography>
		</Paper>,
	];
};
