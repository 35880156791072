import { regexNewline } from "lib/consts";

export function isArray(value: unknown): value is unknown[] {
	return value != null && Array.isArray(value);
}

export function fetchJSON(url: string) {
	return fetch(url).then((response) => response.json());
}

export function isObject(obj: unknown): obj is object {
	return typeof obj === "object" && obj !== null;
}

export function splitOnNewLine(content: string) {
	return content.split(new RegExp(regexNewline));
}

export function filterEmptyLines(content: string[]) {
	return content.filter((n) => {
		const hasNewLine = n.match(regexNewline) !== null;
		const isNotEmpty = n !== "";
		return !hasNewLine && isNotEmpty;
	});
}

export const fixContent = (content: string) => {
	return filterEmptyLines(splitOnNewLine(content));
	// return content
	// 	.split(new RegExp(regexNewline))
	// 	.filter((n) => n.match(regexNewline) === null && n !== "");
};
