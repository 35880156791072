import { isObject } from "lib/helpers";
import { Rating05 } from "types/misc";

export interface IAnmeldelserAPIResponseItem {
	id: number;
	BonusHjemmeside: string;
	BonusLink: string;
	BonusTitle: string;
	FordeleContent: string;
	FordeleTitle: string;
	UlemperContent: string;
	UlemperTitle: string;
	expandableContent: string;
	SideNavn: string;
	AntalStjerner: Rating05;
}

export const isAnmeldelse_API = (
	anmeldelse: unknown
): anmeldelse is IAnmeldelserAPIResponseItem => {
	if (!isObject(anmeldelse)) return false;

	const hasId = "id" in anmeldelse && typeof anmeldelse["id"] === "number";

	const hasSideNavn =
		"SideNavn" in anmeldelse && typeof anmeldelse["SideNavn"] === "string";

	const hasAntalStjerner =
		"AntalStjerner" in anmeldelse &&
		typeof anmeldelse["AntalStjerner"] === "number" &&
		anmeldelse["AntalStjerner"] >= 0 &&
		anmeldelse["AntalStjerner"] <= 5;

	const hasBonusHjemmeside =
		"BonusHjemmeside" in anmeldelse &&
		typeof anmeldelse["BonusHjemmeside"] === ("string" || "null");

	const hasBonusLink =
		"BonusLink" in anmeldelse &&
		typeof anmeldelse["BonusLink"] === ("string" || "null");

	const hasBonusTitle =
		"BonusTitle" in anmeldelse &&
		typeof anmeldelse["BonusTitle"] === "string";

	const hasFordeleTitle =
		"FordeleTitle" in anmeldelse &&
		typeof anmeldelse["FordeleTitle"] === "string";

	const hasFordeleContent =
		"FordeleContent" in anmeldelse &&
		typeof anmeldelse["FordeleContent"] === "string";

	const hasExpandableContent =
		"expandableContent" in anmeldelse &&
		typeof anmeldelse["expandableContent"] === "string";

	const hasUlemperContent =
		"UlemperContent" in anmeldelse &&
		typeof anmeldelse["UlemperContent"] === "string";

	const hasUlemperTitle =
		"UlemperTitle" in anmeldelse &&
		typeof anmeldelse["UlemperTitle"] === "string";

	return (
		hasId &&
		hasBonusHjemmeside &&
		hasBonusLink &&
		hasBonusTitle &&
		hasFordeleTitle &&
		hasFordeleContent &&
		hasExpandableContent &&
		hasUlemperContent &&
		hasUlemperTitle &&
		hasSideNavn &&
		hasAntalStjerner
	);
};
