import React from "react";
import Box from "@mui/material/Box";

import { flexCenterColumnStyle } from ".";

const CenterColumnBox = ({ children }: { children: React.ReactNode }) => (
	<Box sx={flexCenterColumnStyle}>{children}</Box>
);

export default CenterColumnBox;
