import React from "react";
import { useEffect } from "react";

import Grid from "@mui/material/Grid";

import StdView from "../components/common/StdView";
import { AnmeldelsesItem } from "../components/Anmeldelser/AnmeldelsesItem";
import { ApiEndpoints } from "lib/consts";
import { PageHandler } from "App";
import { isAnmeldelse_API } from "lib/AnmeldelseAPI";
import { fixContent } from "lib/helpers";

import { Box } from "@mui/system";
import Spinner from "components/common/Spinner";

import { Helmet } from "react-helmet";

const Anmeldelser = () => {
	const [items, setItems] = React.useState<React.JSX.Element[]>([]);

	useEffect(() => {
		fetch(ApiEndpoints.getAnmeldelser)
			.then((response) => response.json())
			.then((data) => {
				if (data != null && Array.isArray(data)) {
					data.forEach((anmeldelse) => {
						if (isAnmeldelse_API(anmeldelse)) {
							const ulemperContent = fixContent(
								anmeldelse.UlemperContent
							);

							const fordeleContent = fixContent(
								anmeldelse.FordeleContent
							);

							setItems((prev) => [
								...prev,
								<AnmeldelsesItem
									//bgcolor={'primary.main'}
									key={`${anmeldelse.id}ID`}
									elevation={2}
									content={{
										AntalStjerner: anmeldelse.AntalStjerner,
										SideNavn: anmeldelse.SideNavn,
										FordeleTitle: anmeldelse.FordeleTitle,
										FordeleContent: fordeleContent,
										UlemperTitle: anmeldelse.UlemperTitle,
										UlemperContent: ulemperContent,
										BonusTitle: anmeldelse.BonusTitle,
										BonusHjemmeside:
											anmeldelse.BonusHjemmeside,
										BonusLink: anmeldelse.BonusLink,
										expandableContent:
											anmeldelse.expandableContent,
									}}
								/>,
							]);
						}
					});
				}
			})
			.catch((error) => {
				// Handle the error
				//console.log(error);

				(function () {
					error;
				})();
			});
	}, []);

	return (
		<>
			<Helmet>
				<title>
					Bonus Lake: Anmeldelser her finder du anmeldelser af de
					sider vi tilbyder bonus fra. Bonuslake.dk Din guide til de
					nyeste, gratis, odds og betting bonusser i Danmark. Der er
					bonusser til sider såsom, Tipwin, Betsson, betfair,
					LeoVegas, bet365, unibet og de er alle gratis - uden nogen
					form for betaling. Vi har sammenarbejde med de bedste online
					bookmakere og casinoer i Danmark. Desuden sammenarbejder vi
					med Betfair, Betsson, Bet365 og CASHPOINT. Så mangler du en
					overskuelig side til at finde de bedste; nyeste og mest
					eksklusive bonusser, så er du kommet til det rette sted.
					Bonuslake.dk - Din vejledning til gambling, casino og
					betting bonusser i Danmark.
				</title>
				<meta name="prerender-status-code" content="200"></meta>
			</Helmet>
			<StdView
				noSmallLogo
				largeLogo
				index={PageHandler.getIndexByName("Anmeldelser")}
			>
				<Grid
					sx={{
						paddingTop: 3,
						paddingBottom: "0.5rem",
						marginLeft: (theme) => theme.spacing(2),
					}}
					container
					spacing={2}
				>
					{items.length !== 0 ? (
						items.map((item) => item)
					) : (
						<Box
							component={"span"}
							sx={{
								marginLeft: "calc(50% - calc(75px/2))",
								minHeight: "100px",
								marginTop: 5,
							}}
						>
							<Spinner size={75} />
						</Box>
					)}
				</Grid>
			</StdView>
		</>
	);
};

export default Anmeldelser;

//#region obsolete code

// {makeXAnm(8)}
// {
/* Background Image */
// }
// {
/* <Grid item xs={12}>
				<Box
					sx={{
						backgroundImage: 'url("/assets/bgb.png")',
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						height: "45vh",
						marginBottom: "1rem",
					}}
				/>
			</Grid> */
// }

// Id: 2 :
// BonusHjemmeside: "ihi"
// BonusTitle: "gyi"
// FordeleContent: "jhoj"
// FordeleTitle: "s"
// UlemperContent: "g"
// UlemperTitle: "ioj"
// expandableContent: "h"

// const makeXAnm = (numAnmI: number) => {
// 	const items = [];
// 	for (let i = numAnmI; i > 0; i--)
// 		items.push(
// 			<AnmeldelsesItem
// 				//bgcolor={'primary.main'}
// 				key={i}
// 				elevation={2}
// 				content={{
// 					FordeleTitle: "string",
// 					FordeleContent: ["string"],
// 					UlemperTitle: "string",
// 					UlemperContent: ["string"],
// 					BonusTitle: "string",
// 					BonusHjemmeside: "string",
// 					expandableContent: <>Test</>,
// 				}}
// 			/>
// 		);
// 	return items.reverse();
// };
// type AnmeldelserAPIResponse = IAnmeldelserAPIResponseItem[];

//#endregion
