import React from "react";

import Box from "@mui/system/Box";

const LargeHeadImg = () => {
	return (
		<Box
			sx={{
				backgroundImage: 'url("/assets/bgb.png")',
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundOrigin: "content-box",
				backgroundPosition: "50% 50%",

				height: {
					xs: "30vh",
					sm: "35vh",
					md: "40vh",
				},
				marginBottom: "1rem",
				minHeight: {
					xs: "150px",
					sm: "200px",
					md: "275px",
					lg: "300px",
					xl: "600px",
				},
			}}
		/>
	);
};

export default LargeHeadImg;
