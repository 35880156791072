import React from "react";

import Box from "@mui/system/Box";

const SmallLogoImg = () => {
	return (
		<Box
			sx={{
				marginRight: "auto",
				marginLeft: "auto",
				margin: "0 auto",
				overflow: "hidden",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<img
				style={{
					maxWidth: "40%",
					maxHeight: "20vh",
				}}
				alt=""
				src="/bl.png"
			/>
		</Box>
	);
};

export default SmallLogoImg;
