import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/system/Box";
import ToggleButton from "@mui/material/ToggleButton";

import { Link } from "react-router-dom";

import { PageHandler } from "../App";

const Logo: React.FC = () => {
	return (
		<Box component={Link} to="https://bonuslake.dk" rel="canonical">
			<Box
				// onClick={() => {
				// 	document.location = "/";
				// }}
				component="img"
				src="/assets/customcolor_logo_transparent_background.png"
				sx={{
					cursor: "pointer",
					maxWidth: "5rem",
					marginRight: (theme) => theme.spacing(3),
				}}
			></Box>
		</Box>
	);
};

const NavBar = (props: any) => {
	return (
		<AppBar position="static" sx={{}}>
			<Toolbar>
				<Logo />
				<nav>
					{PageHandler.getPagesSortedByIndex().map((p) => {
						return (
							<ToggleButton
								key={p.Index}
								value={{}}
								selected={props.s === p.Index}
								sx={{
									color: "white",
									textDecoration: "none",
									border: "none",
									marginRight: "0.5rem",
								}}
								component={Link}
								to={p.Path}
							>
								{p.Title}
							</ToggleButton>
						);
					})}

					{/* {PagesList.map((p) => {
						return (
							<ToggleButton
								key={p.index}
								value={{}}
								selected={props.s === p.index}
								sx={{
									color: "white",
									textDecoration: "none",
									border: "none",
									marginRight: "0.5rem",
								}}
								component={Link}
								to={p.Path}
							>
								{p.Name}
							</ToggleButton>
						);
					})} */}
				</nav>
			</Toolbar>
		</AppBar>
	);
};

export default NavBar;
