import React from "react";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";
//import useTheme from '@mui/material/styles/useTheme';

import { Link as RouterLink } from "react-router-dom";

//region styling
const imageContainerStyle = {
	margin: "0 auto",
	overflow: "hidden",
};

const imageStyle = {
	width: "100%",
	height: "100%",
	objectFit: "contain",
};

const gridItemStyle = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	textAlign: "center",
	margin: "auto", // Center the items
	marginLeft: "0.5rem",
	marginRight: "0.5rem",
	padding: 1,
	paddingTop: "1.5rem",
};
//region unused shadows
//TOO BIG
// `
// 	1px 1px 1.3px hsl(288deg 100% 0% / 0.77),
//     4.7px 4.7px 6.2px -0.5px hsl(288deg 100% 0% / 0.71),
//     9.9px 9.9px 13px -1.1px hsl(288deg 100% 0% / 0.64),
//     19.3px 19.3px 25.4px -1.6px hsl(288deg 100% 0% / 0.57),
//     35.4px 35.4px 46.6px -2.2px hsl(288deg 100% 0% / 0.51),
//     60.8px 60.8px 80px -2.7px hsl(288deg 100% 0% / 0.44),
//     98.1px 98.1px 129px -3.3px hsl(288deg 100% 0% / 0.38),
//     150px 150px 197.3px -3.8px hsl(288deg 100% 0% / 0.31)
// 							`

//Broken not yet tried
//   --shadow-color: 0deg 0% 0%;
//   --shadow-elevation-low:
//     0.5px 0.6px 0.7px hsl(var(--shadow-color) / 0.25),
//     0.9px 1px 1.2px -2px hsl(var(--shadow-color) / 0.19),
//     2.6px 3.2px 3.7px -4px hsl(var(--shadow-color) / 0.13);
//   --shadow-elevation-medium:
//     0.5px 0.6px 0.7px hsl(var(--shadow-color) / 0.26),
//     1.4px 1.7px 2px -1.3px hsl(var(--shadow-color) / 0.21),
//     4.7px 5.7px 6.6px -2.7px hsl(var(--shadow-color) / 0.16),
//     13.1px 15.9px 18.5px -4px hsl(var(--shadow-color) / 0.11);
//   --shadow-elevation-high:
//     0.5px 0.6px 0.7px hsl(var(--shadow-color) / 0.24),
//     2.2px 2.7px 3.1px -0.6px hsl(var(--shadow-color) / 0.22),
//     4.7px 5.7px 6.6px -1.1px hsl(var(--shadow-color) / 0.2),
//     9.5px 11.6px 13.5px -1.7px hsl(var(--shadow-color) / 0.18),
//     17.9px 21.8px 25.4px -2.3px hsl(var(--shadow-color) / 0.15),
//     31.3px 38.1px 44.4px -2.9px hsl(var(--shadow-color) / 0.13),
//     51px 62.1px 72.3px -3.4px hsl(var(--shadow-color) / 0.11),
//     78.5px 95.4px 111.2px -4px hsl(var(--shadow-color) / 0.08);
//
// stadigt for meget `
// 	1px 1px 1.3px hsl(288deg 100% 0% / 0.83),
// 	2.8px 2.8px 3.7px -1.3px hsl(288deg 100% 0% / 0.69),
// 	9.1px 9.1px 12px -2.5px hsl(288deg 100% 0% / 0.55),
// 	25px 25px 32.9px -3.8px hsl(288deg 100% 0% / 0.4);
// `
//endregion unused shadows

const btnShadow = {
	boxShadow: `
		0.2px 0.6px 0.6px hsl(288deg 100% 0% / 0.79),
		0.3px 1.1px 1.1px -1.9px hsl(288deg 100% 0% / 0.61),
		0.8px 3.2px 3.1px -3.8px hsl(288deg 100% 0% / 0.43);
	`,
};
//endregion styling

type BonusItemProps = {
	title: string;
	subtitle: string;
	predefinedText: string;
	siteImageName: string;
	link: string;
};

// Separate functional component for BonusItem
export const BonusItem = ({
	title,
	subtitle,
	predefinedText,
	siteImageName,
	link,
}: BonusItemProps) => {
	const avatarHeight = "10rem"; // Set the desired height
	//const theme = useTheme();

	return (
		<Paper elevation={3} sx={{ ...gridItemStyle, height: "100%" }}>
			<Box
				sx={{
					height: avatarHeight,
					...imageContainerStyle,
				}}
			>
				<Box
					sx={imageStyle}
					component="img"
					src={"/assets/" + siteImageName}
					alt={`The logo from ${title}`}
					title={`${title}`}
				/>
			</Box>

			<Box
				sx={{
					textAlign: "center",
					width: "100%",
					flexGrow: "1",
					justifyContent: "space-between",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Typography variant="h4" color="text.primary">
					{title}
				</Typography>

				<Typography variant="subtitle1" color="text.primary">
					{subtitle}
				</Typography>
				<Typography
					variant="body2"
					color="text.primary"
					sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
				>
					{predefinedText}
				</Typography>

				<Divider variant="middle" />

				<Button
					sx={{
						...btnShadow,
						// boxShadow:
						// 	'0 0.05em 0.25em 0.25em ' + theme.palette.shadow,
						justifySelf: "end",
						alignSelf: "center",
						marginTop: "0.5rem",
					}}
					variant="contained"
					target="_blank"
					color="secondary"
					component={RouterLink}
					to={link}
				>
					{"Gå til side"}
				</Button>
			</Box>
		</Paper>
	);
};
