// import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { darkTheme } from "index";

import { ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";

import Homepage from "./pages/Homepage";
import OmOs from "./pages/OmOs";
import Ansvarlighed from "./pages/Ansvarlighed";
import Kontakt from "./pages/Kontakt";
import Anmeldelser from "./pages/Anmeldelser";
import NotFound from "pages/NotFound";

import Spinner from "components/common/Spinner";

import type { RouteObject } from "react-router-dom";

// interface  {
// 	path?: string;
// 	index?: boolean;
// 	children?: React.ReactNode;
// 	caseSensitive?: boolean;
// 	id?: string;
// 	loader?: LoaderFunction;
// 	action?: ActionFunction;
// 	element?: React.ReactNode | null;
// 	hydrateFallbackElement?: React.ReactNode | null;
// 	errorElement?: React.ReactNode | null;
// 	Component?: React.ComponentType | null;
// 	HydrateFallback?: React.ComponentType | null;
// 	ErrorBoundary?: React.ComponentType | null;
// 	handle?: RouteObject["handle"];
// 	shouldRevalidate?: ShouldRevalidateFunction;
// 	lazy?: LazyRouteFunction<RouteObject>;
// }

//#region Navigation Classes
abstract class PageBase {
	private _Name: string;
	private _Title: string;
	private _Component: React.JSX.Element;

	constructor({
		Name,
		Title,
		Component,
	}: {
		Name: string;
		Title: string;
		Component: React.JSX.Element;
	}) {
		this._Name = Name;
		this._Title = Title;
		this._Component = Component;
	}

	public get Name() {
		return this._Name;
	}

	public get Title() {
		return this._Title;
	}

	public get Component() {
		return this._Component;
	}
}

class ErrorPage extends PageBase {
	constructor({
		Title,
		Name = Title,
		Component,
	}: {
		Title: string;
		Name?: string;
		Component: React.JSX.Element;
	}) {
		super({
			Title,
			Name,
			Component,
		});
	}
}

class NavigablePage extends PageBase {
	private _Path: string;
	private _Index: number;

	constructor({
		Title,
		Name = Title,
		Component,
		Path,
		Index,
	}: {
		Title: string;
		Name?: string;
		Path: string;
		Component: React.JSX.Element;
		Index: number;
	}) {
		super({
			Title,
			Name,
			Component: Component,
		});
		this._Path = Path;
		this._Index = Index;
	}

	public get Path() {
		return this._Path;
	}

	public get Index() {
		return this._Index;
	}
}

class PageContainer {
	private _pages: NavigablePage[];
	private _NotFound: ErrorPage;

	constructor({
		pages = [],
		notFound,
	}: {
		pages?: NavigablePage[];
		notFound: ErrorPage;
	}) {
		this._pages = pages ?? [];
		this._NotFound = notFound;
	}

	get NotFoundPage() {
		return this._NotFound;
	}

	addPage(page: NavigablePage) {
		this._pages.push(page);
	}

	getPagesAsArray() {
		return this._pages.slice();
	}

	getPagesSortedByIndex() {
		return this._pages.sort((a, b) => a.Index - b.Index);
	}

	getAsRoutes(): RouteObject[] {
		return this.getPagesSortedByIndex().map((p) => {
			return {
				path: p.Path,
				element: p.Component,
				errorElement: this._NotFound.Component,
			};
		});
	}

	getPagesAsRoutes() {
		return this.getPagesSortedByIndex().map((p) => {
			return <Route key={p.Index} path={p.Path} element={p.Component} />;
		});
	}

	getPageByTitle(name: string): NavigablePage {
		const possiblePage = this._pages.find((p) => p.Title === name);
		if (possiblePage === undefined) {
			throw new Error("Page not found");
		}
		return possiblePage;
	}

	getPageByPath(path: string) {
		return this._pages.find((p) => p.Path === path);
	}
	getIndexByName(name: string) {
		const possibleIndex = this._pages.findIndex((p) => p.Name === name);
		if (possibleIndex === -1) throw new Error(`Name [${name}] not found`);
		return possibleIndex;
	}

	getIndexByTitle(title: string) {
		const possibleIndex = this._pages.findIndex((p) => p.Title === title);
		if (possibleIndex === -1) throw new Error(`Title [${title}] not found`);
		return possibleIndex;
	}

	getIndexByPath(path: string) {
		const possibleIndex = this._pages.findIndex((p) => p.Path === path);
		if (possibleIndex === -1) throw new Error(`Path [${path}] not found`);
		return possibleIndex;
	}
}

//#endregion

const PageNotFound = new ErrorPage({
	Title: "404",
	Name: "404",
	Component: <NotFound />,
});

const PageHomepage = new NavigablePage({
	Title: "Home",
	Name: "Homepage",
	Path: "/",
	Component: <Homepage />,
	Index: 0,
});

const PageOmOs = new NavigablePage({
	Title: "Om os",
	Name: "OmOs",
	Path: "/about",
	Component: <OmOs />,
	Index: 1,
});

const PageAnsvarlighed = new NavigablePage({
	Title: "Ansvarlig Gambling",
	Name: "Ansvarlighed",
	Path: "/ansvarlighed",
	Component: <Ansvarlighed />,
	Index: 2,
});

const PageAnmeldelser = new NavigablePage({
	Title: "Anmeldelser",
	Path: "/anmeldelser",
	Component: <Anmeldelser />,
	Index: 3,
});

const PageKontakt = new NavigablePage({
	Title: "Kontakt",
	Path: "/kontakt",
	Component: <Kontakt />,
	Index: 4,
});

const PageHandler = new PageContainer({ notFound: PageNotFound });

PageHandler.addPage(PageHomepage);
PageHandler.addPage(PageOmOs);
PageHandler.addPage(PageAnsvarlighed);
PageHandler.addPage(PageAnmeldelser);
PageHandler.addPage(PageKontakt);

const AllGeneratedRoutes: RouteObject[] = PageHandler.getAsRoutes();

const InclusiveWrapper = ({ children }: { children?: React.ReactNode }) => (
	<ThemeProvider theme={darkTheme}>
		<CssBaseline />
		<React.StrictMode>{children}</React.StrictMode>
	</ThemeProvider>
);

const router = createBrowserRouter(AllGeneratedRoutes, {});

const App = () => {
	return (
		<InclusiveWrapper>
			<RouterProvider fallbackElement={<Spinner />} router={router} />
		</InclusiveWrapper>
	);
};

export { PageHandler };

export default App;

//#region old code

// const PagesList = PageHandler.getPagesAsArray();

// PagesList.sort((a, b) => a.Index - b.Index);

// console.log(AllGeneratedRoutes);

// const router = createBrowserRouter(
// 		[
// 		{
// 			path: "/",
// 			Component: () => <Spinner />,
// 		},
// 	]
// );

/* <BrowserRouter>
	<Routes>
		{AllGeneratedRoutes}
		<Route path="*" element={<NotFound />} />
	</Routes>
</BrowserRouter>; */

// const App = () => {
// 	return (
// 		<>
// 			<Router>
// 				<Routes>
// 					{PageHandler.getPagesAsRoutes()}
// 					<Route path="*" element={<NotFound />} />
// 				</Routes>
// 			</Router>
// 		</>
// 	);
// };

// const pgs = PagesList.map((p) => {
// 	return <Route key={p.Index} path={p.Path} element={p.Element} />;
// });

// export const PagesList = [
// 	PageHome,
// 	PageOmOs,
// 	PageAnsvarlighed,
// 	PageAnmeldelser,
// 	PageKontakt,
// ];

/* <Route path="/" element={<Homepage />} />
					<Route path="/about" element={<OmOs />} />
					<Route path="/ansvarlighed" element={<Ansvarlighed />} />
					<Route path="/anmeldelser" element={<Anmeldelser />} />
					<Route path="/kontakt" element={<Kontakt />} /> */

/* <Route path="/" element={<Homepage />} />
					<Route path="/about" element={<OmOs />} />
					<Route path="/ansvarlighed" element={<Ansvarlighed />} />
					<Route path="/anmeldelser" element={<Anmeldelser />} />
					<Route path="/kontakt" element={<Kontakt />} /> */

// type page = {
// 	Name: string;
// 	Path: string;
// 	Element: React.JSX.Element;
// 	n: number;
// };

// export const PageHome: page = {
// 	Name: "HOME",
// 	Path: "/",
// 	Element: <Homepage />,
// 	n: 0,
// };

// export const PageOmOs: page = {
// 	Name: "Om os",
// 	Path: "/about",
// 	Element: <OmOs />,
// 	n: 1,
// };

// export const PageAnsvarlighed: page = {
// 	Name: "Ansvarlig Gambling",
// 	Path: "/ansvarlighed",
// 	Element: <Ansvarlighed />,
// 	n: 2,
// };
// export const PageAnmeldelser: page = {
// 	Name: "anmeldelser",
// 	Path: "/anmeldelser",
// 	Element: <Anmeldelser />,
// 	n: 3,
// };
// export const PageKontakt: page = {
// 	Name: "Kontakt",
// 	Path: "/kontakt",
// 	Element: <Kontakt />,
// 	n: 4,
// };

//#endregion
