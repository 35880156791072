/* eslint-disable react/no-deprecated */
import React from "react";

// ! Used for static page generation NOT USED ANYMORE 160124
// eslint-disable-next-line react/no-deprecated
// import { hydrate, render } from "react-dom";

import ReactDOM from "react-dom";

import "./styles/index.css";
// import reportWebVitals from "./reportWebVitals";

import { createTheme, PaletteColorOptions } from "@mui/material/styles";

import App from "./App";
import "./styles/index.css";

const linkColor = "#f0bdff";
const backgroundColor0 = "#050106";
const primaryColor = "#121212";
const titleColor = "#9f7cc5";
const broedTekstSize = "0.85rem";
const bodySize2 = "9px";

declare module "@mui/material/styles" {
	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		title?: React.CSSProperties;
		btekst?: React.CSSProperties;
	}

	//! Prøv at fjerne denne senere
	interface TypographyVariants {
		title: React.CSSProperties;
		btekst: React.CSSProperties;
	}
}

declare module "@mui/material/styles/createPalette" {
	interface PaletteOptions {
		tertiary?: PaletteColorOptions;
		shadow?: PaletteColorOptions;
		red: PaletteColorOptions;
		green: PaletteColorOptions;
		lime: PaletteColorOptions;
	}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		title: true;
		btekst: true;
	}
}

declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		red: true;
		green: true;
		lime: true;
	}
}

declare module "@mui/material/SvgIcon" {
	interface SvgIconPropsColorOverrides {
		red: true;
		green: true;
		lime: true;
	}
}

declare module "@mui/material/CircularProgress" {
	interface CircularProgressPropsColorOverrides {
		tertiary: true;

		red: true;
		green: true;
		lime: true;
	}
}

export const darkTheme = createTheme({
	typography: {
		title: {
			color: titleColor,
		},
		h1: { color: titleColor },
		h2: { color: titleColor },
		h3: { color: titleColor },
		h4: { color: titleColor },
		h5: { color: titleColor },
		h6: { color: titleColor },
		btekst: {
			fontSize: broedTekstSize,
		},
		body2: {
			fontSize: bodySize2,
		},
	},
	palette: {
		mode: "dark",
		primary: { main: primaryColor },
		secondary: { main: "#5f259f" },
		tertiary: {
			main: "#7f51b2",
		},
		// shadow: backgroundColor0,
		red: { main: "#ff0000" },
		green: { main: "#00ff00" },
		lime: { main: "#8bc24a" },
	},
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					color: linkColor,
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: backgroundColor0, // Change the background color as desired
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: backgroundColor0, // Change the background color as desired
				},
			},
		},
	},
});

// const Content = () => {
// 	return (
// 		<ThemeProvider theme={darkTheme}>
// 			<CssBaseline />
// 			<React.StrictMode>
// 				<App />
// 			</React.StrictMode>
// 		</ThemeProvider>
// 	);
// };

//https://web.dev/articles/prerender-with-react-snap
ReactDOM.render(<App />, document.getElementById("root"));
const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
	ReactDOM.hydrate(<App />, rootElement);
} else {
	ReactDOM.render(<App />, rootElement);
}

// // https://github.com/stereobooster/react-snap
// const rootElement = document.getElementById("root");
// if (rootElement?.hasChildNodes()) {
// 	hydrate(<Content />, rootElement);
// } else {
// 	render(<Content />, rootElement);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// ? reportWebVitals();
