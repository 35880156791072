/* eslint-disable no-lone-blocks */

import React, { useEffect, useState } from "react";
import { Grid, Box, Paper, Button, Collapse, Typography } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ImageRating } from "./ImageRating";
import { paperConstantStyle } from "./shared";
import { GenerateContent } from "./GenerateContent";

import { isArray, fetchJSON } from "lib/helpers";
import { isTitleAvatar } from "types/anmeldelser";
import type { ITitleAvatar, AndmeldelseContent } from "types/anmeldelser";
import { ApiEndpoints } from "lib/consts";

type AnmeldelsesItemProps = {
	padding?: string;
	bgcolor?: string;
	elevation?: number;
	content: AndmeldelseContent;
};
const AnmeldelsesItem: React.FC<AnmeldelsesItemProps> = ({
	padding,
	bgcolor,
	elevation,
	content,
}) => {
	const defaultPicture = "";

	const [imageName, setImageName] = useState<string>("");

	useEffect(() => {
		fetchJSON(ApiEndpoints.getTitleAndAvatar)
			.then((data) => {
				if (isArray(data)) {
					const list: ITitleAvatar[] = [];

					data.forEach((n: unknown) => {
						if (isTitleAvatar(n)) {
							list.push(n);
						}
					});

					setImageName(
						list.find((n) => n.title === content.SideNavn)
							?.avatarSrc ?? defaultPicture
					);
				}
			})
			.catch((error) => {
				// Handle the error
				// console.log(error);
			});
	});

	const paperContextDependentStyle = {
		padding: padding ? padding : "1rem",
		bgcolor: bgcolor ? bgcolor : "primary.main",
	};

	const [fordele, ulemper, bonus] = GenerateContent(
		"",
		content.FordeleContent,
		"",
		content.UlemperContent,
		content.BonusTitle,
		content.BonusHjemmeside,
		content.BonusLink
	);
	// content.UlemperTitle;
	// content.FordeleTitle;
	// const spacingBetween = 2;
	const contentGap = 6;

	const [expanded, setExpanded] = useState(false);

	const exp = content.expandableContent
		.split(new RegExp(`"(.{1,}?\n*?.*?)"`)) //OLD "(.*?)"
		.filter((n) => n !== "\n\r");
	//n !== "" &&
	// console.log(exp);

	return (
		<Grid
			sx={{
				paddingRight: (theme) => theme.spacing(2),
				paddingBottom: (theme) => theme.spacing(2),

				marginBottom: (theme) => theme.spacing(1),
				// transition: `height 4s linear`
			}}
			item
			xs={12}
		>
			<Paper
				elevation={elevation ? elevation : 1}
				sx={{
					...paperContextDependentStyle,
					...paperConstantStyle,

					// transition: `height 4s linear`
				}}
			>
				<Grid
					container
					justifyContent="space-between"
					direction={{ xs: "column", sm: "row" }}
					sx={
						{
							// transition: `height 4s linear`,
						}
					}
				>
					<Grid item xs={12} sm={6}>
						<Grid
							sx={{ height: "100%" }}
							container
							justifyContent={{
								md: "flex-start",
								sm: "space-between",
							}}
							gap={contentGap}
							direction={{ xs: "column", sm: "row" }}
						>
							<Grid
								item
								xs={12}
								sm={"auto"}
								display={"flex"}
								justifyContent={"center"}
							>
								<ImageRating
									rating={content.AntalStjerner}
									imgName={imageName}
								/>
							</Grid>
							{/* <SpaceBetween /> */}
							<Grid item xs={12} sm={"auto"}>
								<Typography variant="h4">
									{content.FordeleTitle}
								</Typography>
								{fordele}
							</Grid>
						</Grid>
					</Grid>
					{/* <Grid item xs={12} sm={6}>
						<Grid
							sx={{ height: "100%" }}
							container
							direction={{ xs: "column", sm: "row" }}
						>
							<Grid item xs={12} md={"auto"} sm={"auto"}>
								<ImageRating
									rating={content.AntalStjerner}
									imgName={imageName}
								/>
							</Grid>
							{/* <Grid
								item
								xs={false}
								// md={spacingBetween - 1}
								// sm={spacingBetween}
								// flex={"1 1 auto"}
							></Grid> */}
					{/* <SpaceBetween /> */}

					{/* <Grid item xs={12} sm={"auto"}> */}
					{/* {fordele} */}
					{/* </Grid>
						</Grid>
					</Grid>  */}
					<Grid
						item
						xs={12}
						md={false}
						sm={false}
						flex={{ xs: "1 0 1rem", md: "0 0 0" }}
					></Grid>

					<Grid sx={{ marginBottom: 1.5 }} item xs={12} sm={6}>
						<Grid
							sx={{ height: "100%" }}
							container
							justifyContent={{
								md: "flex-end",
								sm: "space-between",
							}}
							gap={contentGap}
							direction={{ xs: "column", sm: "row" }}
						>
							<Grid item xs={12} sm={"auto"}>
								<Typography align="right" variant="h4">
									{content.UlemperTitle}
								</Typography>
								{ulemper}
							</Grid>
							{/* <SpaceBetween /> */}
							<Grid item xs={12} sm={"auto"}>
								{bonus}
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} sm={12}>
						<Grid container direction={"row"} sx={{}}>
							<Grid
								sx={{
									// position: 'relative',
									// bottom: (expanded ? 0 : (theme) => theme.spacing(-4)),
									marginBottom: expanded ? undefined : -4,
									transition: `margin 0.5s linear`,
									transitionDelay: `${
										expanded ? "0s" : "1.5s"
									}`,
								}}
								item
								xs={12}
								sm={12}
							>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<Button
										onClick={() => setExpanded(!expanded)}
										disableElevation
										variant="contained"
										color="secondary"
									>
										<ExpandMoreIcon color="primary" />
									</Button>
								</Box>
							</Grid>
							<Grid sx={{}} item xs={12} sm={12}>
								<Collapse
									in={expanded}
									collapsedSize={0}
									// timeout={2000}
									// ! Kun hvis expanded find ud hvilken en vi skal bruge {...(expanded ? { timeout: 2000 } : {})}
									sx={
										{
											// transition: `position 0.5s linear`,
											// transitionDelay: `0s`,
										}
									}
									{...(expanded
										? { timeout: 2000 }
										: { timeout: 2000 })}
								>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											flexDirection: "column",
											// transition: `opacity 1s ease-in`,
											// opacity: expanded ? 1 : 0,
										}}
									>
										{exp.map((n, i) => {
											if (i == 0) {
												return (
													<Typography
														variant="h6"
														sx={{ marginBottom: 1 }}
														key={i}
													>
														{n}
													</Typography>
												);
											} else if (
												n.match(
													new RegExp(`(\\[.*?\\])`)
												)
											) {
												return (
													<Typography
														variant="h6"
														key={i}
													>
														{n?.slice(1, -1)}
													</Typography>
												);
											} else if (n == " ") {
												return (
													<Typography
														variant="h6"
														sx={{ marginBottom: 1 }}
														key={i}
													>
														{"\n"}
													</Typography>
												);
											} else {
												return (
													<Typography key={i}>
														{n}
													</Typography>
												);
											}
										})}
										{/* {content.expandableContent} */}
									</Box>
								</Collapse>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
};

export { AnmeldelsesItem };
