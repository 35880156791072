import React from "react";

import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

import "./styles/rofus.css";

type BottomImageStyleType = {
	margin?: string;
	overflow?: string;
	marginLeft?: string;
	marginRight?: string;
};

type StyleType = {
	marginTop?: string;
	padding?: string;
};

type RofusProps = {
	dontKeepAtBot?: boolean;
	noPadding?: boolean;
};

const bottomImagesStyle: BottomImageStyleType = {
	margin: "0 auto",
	overflow: "hidden",
	marginLeft: "0.1rem",
	marginRight: "0.1rem",
};

const Rofus: React.FC<RofusProps> = (props) => {
	let fStyle: StyleType = props.dontKeepAtBot
		? {}
		: {
				marginTop: "auto",
		  };
	fStyle = props.noPadding
		? { ...fStyle, padding: "0px" }
		: { ...fStyle, padding: "0.25rem" };

	const component = (
		<Box component={"footer"} sx={{ ...fStyle, paddingTop: 1 }}>
			<Typography textAlign={"center"}>
				Spil ansvarligt +18. Spil kan være vanedannende. StopSpillet er
				Spillemyndighedens hjælpelinje til ansvarligt spil. Hvis du har
				brug for hjælp, kan du ringe til +45 70 22 28 25.
			</Typography>

			<Typography textAlign={"center"}>
				SPILLEMYNDIGHEDENS HJÆLPELINJE:{" "}
				<Link component={RouterLink} to="https://www.stopspillet.dk/">
					{" "}
					STOPSPILLET.DK
				</Link>{" "}
				– Tlf. 70222825 | SELVUDELUKKELSE:{" "}
				<Link component={RouterLink} to="https://rofus.nu">
					ROFUS.NU
				</Link>{" "}
				| SPIL ANSVARLIGT | 18+
			</Typography>
			<Box
				padding="1rem"
				display="flex"
				flexDirection={"row"}
				justifyContent="center"
				alignItems="center"
			>
				<Box sx={{ ...bottomImagesStyle }}>
					<img id="botImg" alt="" src="/assets/stopSpil.webp" />
				</Box>
				<Box sx={{ ...bottomImagesStyle }}>
					<img id="botImg" alt="" src="/assets/rofus.png" />
				</Box>
				<Box sx={{ ...bottomImagesStyle }}>
					<img id="botImg" alt="" src="/assets/18p.webp" />
				</Box>
			</Box>
		</Box>
	);
	return component;
};

export default Rofus;
