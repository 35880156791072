import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import StdView from "components/common/StdView";
import { Grid } from "@mui/material";
import { PageHandler } from "App";

import { Link as RouterLink } from "react-router-dom";

const Kontakt: React.FC = () => {
	return (
		<>
			<StdView
				index={PageHandler.getIndexByName("Kontakt")}
				padding={1}
				noSmallLogo
				largeLogo
			>
				<Grid item xs={12}>
					<Typography variant="h4">Kontakt</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography>
						Har du spørgsmål, brug for hjælp eller bare lyst til at
						give os feedback? <br /> Vi er her for at hjælpe! Du kan
						kontakte os på vores mail:
						<Link
							component={RouterLink}
							to="mailto:support@bonuslake.dk"
						>
							support@bonuslake.dk
						</Link>
						<br /> Vores dedikerede supportteam står klar til at
						hjælpe, med at løse eventuelle udfordringer - du måtte
						have. <br />
						Vi bestræber os på at levere hurtige og effektive svar
						for at sikre, at din spiloplevelse igennem os er
						problemfri. <br />
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6">
						Tak fordi du valgte Bonuslake.dk
					</Typography>
				</Grid>
			</StdView>
		</>
	);
};

export default Kontakt;

// #region obsolete code

/* <NavBar s={4} />
			<Box sx={{}}>
				<Box
					sx={{
						marginRight: "auto",
						marginLeft: "auto",
						margin: "0 auto",
						overflow: "hidden",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<img
						style={{
							maxWidth: "40%",
							maxHeight: "20vh",
						}}
						alt=""
						src="/bl.png"
					/>
				</Box>
			</Box>
						<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					minHeight: "calc(80vh - 64px)",
				}}
			>
				<Box
					sx={{
						padding: "0.5rem",
						alignSelf: "flex-start",
					}}
				>
									</Box>
				<Box sx={{ marginTop: "auto", maxHeight: "10rem" }}>
					<Rofus />
				</Box>
			</Box>
 */

//#endregion
