import React from "react";

import { List, Link, Typography } from "@mui/material";

import OrderedListItem from "../components/OrderedListItem";
import CenterRow from "components/common/CenterRow";
import StdView from "components/common/StdView";
import { PageHandler } from "App";

import { Link as RouterLink } from "react-router-dom";

const Ansvarlighed = () => {
	return (
		<>
			<StdView
				index={PageHandler.getIndexByName("Ansvarlighed")}
				noSmallLogo
				largeLogo
			>
				{/* <NavBar s={2} />
			<Box sx={{}}>
				<Box
					sx={{
						marginRight: "auto",
						marginLeft: "auto",
						margin: "0 auto",
						overflow: "hidden",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<img
						style={{
							maxWidth: "40%",
							maxHeight: "20%",
						}}
						alt=""
						src="/bl.png"
					/>
				</Box>
			</Box> */}

				<CenterRow>
					<Typography color="#FFFFFF" variant="h4">
						Ansvarligt Spil hos BonusLake
					</Typography>
					<Typography variant="btekst">
						Velkommen til BonusLake, hvor vi værdsætter og fremmer
						ansvarligt spil som en afgørende del af vores mission.
						<br />
						Vi er dedikerede til at skabe en underholdende
						spiloplevelse, der er sikker, fair og befordrende for
						langvarig glæde.
						<br />
					</Typography>
					<br />

					<Typography variant="title">
						Vores Engagement:
						<br />
					</Typography>
					<List sx={{ listStyle: "decimal", pl: 2 }}>
						<OrderedListItem title="Spil Med Måde">
							Vi opfordrer vores brugere til at spille med fornuft
							og sætte realistiske grænser for deres
							spilaktiviteter. At definere et budget og holde sig
							til det er nøglen til en positiv spiloplevelse.
						</OrderedListItem>

						<OrderedListItem title="Beskyttelse af Unge">
							Vi erkender vigtigheden af at beskytte unge mod at
							deltage i spilaktiviteter. Vores platform er
							designet til at være aldersbegrænset og indeholder
							information om forældrekontrolværktøjer.
						</OrderedListItem>
						<OrderedListItem title="Selvudelukkelse">
							Hvis du føler, at spil begynder at påvirke dit liv
							negativt, tilbyder vi information om
							selvudelukkelsesmuligheder og ressourcer, der kan
							hjælpe dig med at tage kontrollen tilbage.
						</OrderedListItem>
					</List>

					<Typography variant="title">
						Tegn på Problematisk Spil:
						<br />
					</Typography>
					<Typography variant="btekst">
						Det er vigtigt at være opmærksom på tegnene på
						problematisk spiladfærd. <br />
						Hvis du eller nogen, du kender, oplever problemer, såsom
						øget spiltid, jagten på tabte penge eller forsømmelse af
						personlige forpligtelser, er det afgørende at søge
						hjælp.
						<br />
						<br />
					</Typography>

					<Typography variant="title">
						Hvordan Vi Hjælper:
						<br />
					</Typography>
					<List sx={{ listStyle: "decimal", pl: 2 }}>
						<OrderedListItem title="Supportteam">
							Vores supportteam er her for at besvare dine
							spørgsmål og give dig oplysninger om ansvarligt
							spil. Kontakt os når som helst via e-mail{" "}
							<Link
								component={RouterLink}
								to="mailto:support@bonuslake.dk"
							>
								support@bonuslake.dk
							</Link>
						</OrderedListItem>

						<OrderedListItem title="Selvtest">
							Brug vores selvtestværktøj til at evaluere dine
							spilvaner og få anbefalinger til at opretholde en
							sund balance.
						</OrderedListItem>
					</List>

					<Typography variant="title">
						Sammen Skaber Vi et Ansvarligt Spilmiljø:
						<br />
					</Typography>
					<Typography variant="btekst">
						Vi opfordrer vores brugere til at engagere sig i
						dialogen om ansvarligt spil og dele deres erfaringer og
						strategier. <br /> Sammen kan vi skabe et samfund, der
						støtter og inspirerer hinanden til at spille med
						omtanke.
						<br />
						<br />
					</Typography>

					<Typography variant="title">
						Tak, fordi du er en del af BonusLake. Vi stræber efter
						at gøre din spiloplevelse ansvarlig, sjov og bæredygtig.
					</Typography>
				</CenterRow>
			</StdView>
			{/* <br /> */}
			{/* <Rofus /> */}
		</>
	);
};

/* <Typography variant="h4">Ansvarlig Gambling</Typography>
<Typography>
	Vi hos Bonuslake.dk lægger vægt på ansvarlig gambling.
	<br /> Vi opfordrer vores besøgende til at nyde spillet med
	omtanke og være opmærksomme på potentielle risici. <br />
	At spille ansvarligt indebærer at fastsætte grænser for
	indsatser, tid og økonomi samt være opmærksom på tegn på
	afhængighed.
	<br /> Vores mål er at sikre, at spil forbliver en positiv
	og underholdende oplevelse. <br />
	Hvis du eller nogen, du kender, oplever udfordringer med
	spil, opfordrer vi til at søge hjælp.
	<br /> På vores side har du også mulighed for at tilmelde
	dig ROFUS, hvis du har brug for ekstra støtte i at
	kontrollere dit spil.
</Typography> */
export default Ansvarlighed;
