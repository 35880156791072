import * as React from "react";

import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import NavBar from "../NavBar";
import Rofus from "./Rofus";
import LargeHeadImg from "./headder/LargeHeadImg";
import SmallLogoImg from "./headder/SmallLogoImg";

export const StdViewBase: React.FC<{ children?: React.ReactNode }> = (
	props
) => {
	return (
		<>
			<Grid item xs={12}>
				<Box
					sx={{
						backgroundImage: 'url("/assets/bgb.png")',
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						backgroundOrigin: "content-box",
						backgroundPosition: "50% 50%",

						height: {
							xs: "30vh",
							sm: "35vh",
							md: "40vh",
						},
						marginBottom: "1rem",
						minHeight: {
							xs: "150px",
							sm: "200px",
							md: "275px",
							lg: "300px",
							xl: "600px",
						},
					}}
				></Box>
			</Grid>
			<Grid
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
				}}
			>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<Grid sx={{}} container spacing={2}>
						{props.children}
						<Grid item xs={12}>
							{" "}
						</Grid>
						<Grid item xs={12}>
							{" "}
						</Grid>
					</Grid>
				</Box>
				<Grid sx={{ justifySelf: "end" }}>
					<Typography
						sx={{ marginBottom: 0, paddingBottom: 0 }}
						textAlign={"center"}
					>
						Copyright © 2023 - bonuslake.dk.
					</Typography>
					<Rofus />
				</Grid>
			</Grid>
		</>
	);
};

type StdViewProps = {
	index: number;
	noSmallLogo?: boolean;
	largeLogo?: boolean;
	children?: React.ReactNode;
	padding?: number | string /* Padding left */;
};

const StdView: React.FC<StdViewProps> = (props) => {
	return (
		<>
			<NavBar s={props.index} sx={{ marginBottom: 5 }} />
			{props.noSmallLogo ? (
				<></>
			) : (
				<Box sx={{}}>
					<SmallLogoImg />
				</Box>
			)}
			{props.largeLogo ? (
				<Grid item xs={12}>
					<LargeHeadImg />
				</Grid>
			) : (
				<></>
			)}
			<Grid
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					paddingLeft: props.padding ? props.padding : undefined,
				}}
			>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<Grid sx={{}} container spacing={2}>
						{props.children}
						<Grid item xs={12}>
							{" "}
						</Grid>
						<Grid item xs={12}>
							{" "}
						</Grid>
					</Grid>
				</Box>
				<Typography
					sx={{ marginBottom: 0, paddingBottom: 0 }}
					textAlign={"center"}
				>
					Copyright © 2023 - bonuslake.dk.
				</Typography>
				<Rofus />
			</Grid>
		</>
	);
};

export default StdView;
