import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";

import { Helmet } from "react-helmet";

import { BonusItem } from "../components/BonusItem";
import { ApiEndpoints } from "lib/consts";
import StdView from "components/common/StdView";
import { PageHandler } from "App";
import Spinner from "components/common/Spinner";

// Styling constants
const sectionStyle = {
	textAlign: "center",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	marginY: 2,
	paddingTop: "0.5rem",
	marginRight: "2rem",
	marginLeft: "2rem",
};

type BonusItem_API = {
	id: number;
	title: string;
	subtitle: string;
	predefinedText: string;
	avatarSrc: string;
	link: string;
};

type BonusItemList_API = BonusItem_API[];

const Homepage = () => {
	const [listData, setListData] = useState<BonusItemList_API>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<null | string>(null);

	useEffect(() => {
		//Wait 2 seconds before fetching data
		setTimeout(async () => {}, 10000);

		fetch(ApiEndpoints.getBonusPages)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				if (Array.isArray(data)) {
					setListData(data);
					setIsLoading(false);
				} else {
					setError("Data is not an array");
					setIsLoading(false);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
				setError(error.message);
				setIsLoading(false);
			});
	}, []);

	// position: "absolute", top: "100"
	if (isLoading) {
		return (
			<Box
				component={"span"}
				sx={{
					position: "relative",
					top: "40vh",
					left: "50%",
					minHeight: "100px",
				}}
			>
				<Spinner size={75} />
			</Box>
		);
		// return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error}</div>;
	}
	// <Typography marginBottom={1} variant="h3">
	// 	Bonuslake.dk
	// </Typography>

	return (
		<>
			<Helmet>
				<title>
					Bonus Lake: Home her finder du listen over de bonusser vi
					kan tilbyde dig. Bonuslake.dk Din guide til de nyeste,
					gratis, odds og betting bonusser i Danmark. Der er bonusser
					til sider såsom, Tipwin, Betsson, betfair, LeoVegas, bet365,
					unibet og de er alle gratis - uden nogen form for betaling.
					Vi har sammenarbejde med de bedste online bookmakere og
					casinoer i Danmark. Desuden sammenarbejder vi med Betfair,
					Betsson, Bet365 og CASHPOINT. Så mangler du en overskuelig
					side til at finde de bedste; nyeste og mest eksklusive
					bonusser, så er du kommet til det rette sted. Bonuslake.dk -
					Din vejledning til gambling, casino og betting bonusser i
					Danmark.
				</title>
				<meta name="prerender-status-code" content="200"></meta>
			</Helmet>
			<StdView
				index={PageHandler.getIndexByName("Homepage")}
				noSmallLogo
				largeLogo
			>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Box
						sx={{
							...sectionStyle,
						}}
					>
						<Typography
							component={"h1"}
							variant="h6"
							color={"#FFF"}
							textAlign={"center"}
						>
							Velkommen til Bonuslake.dk – her får du de bedste og
							nyeste odds- og bettingbonusser i Danmark!
							<br />
							Vi er her for at krydre din spiloplevelse med
							topklasse tilbud, der løfter stemningen og øger dine
							gevinstchancer.
							<br />
							Lad os komme i gang med sjov og spænding!
						</Typography>
					</Box>
				</Grid>
				<Box
					component="section"
					sx={{ ...sectionStyle, paddingTop: 1 }}
				>
					<Grid container spacing={2} justifyContent="center">
						{listData.map((item) => (
							<Grid
								item
								key={item.id}
								xs={12}
								sm={6}
								md={6}
								lg={6}
							>
								<BonusItem
									title={item.title}
									subtitle={item.subtitle}
									predefinedText={item.predefinedText}
									siteImageName={item.avatarSrc}
									link={item.link}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
			</StdView>
			{/* <Box sx={{}}><Rofus /></Box> */}
		</>
	);
};

export default Homepage;
