import React from "react";

import Box from "@mui/system/Box";
import Rating from "@mui/material/Rating";
// import { imageContainerStyle, imageStyle } from './styles';

import type { Rating05 } from "types/misc";

type ImageRatingProps = {
	rating: Rating05;
	imgName: string;
};

export const ImageRating: React.FC<ImageRatingProps> = ({
	rating,
	imgName,
}) => {
	return (
		<Box
			sx={{
				display: "inline-flex", // Change this to 'inline-flex'
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "flex-start",
			}}
		>
			<img
				src={`/assets/${imgName}`}
				alt={imgName}
				style={{ width: "10rem", height: "auto" }}
			/>
			<Rating name="read-only" value={rating} readOnly />
		</Box>
	);
};
