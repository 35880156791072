export const outerBoxStyles = {
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	padding: "0.5rem",
};

export const innerBoxStyles = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	padding: "0.25rem",
};

export const paperConstantStyle = {
	borderRadius: "1rem",
	// overflow: 'hidden',
	display: "flex",
	flexDirection: "column",
};

export const flexCenterColumnStyle = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
};
