import React from "react";
import { ListItem, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";

type OrderedListItemProps = {
	title: string;
	children: React.ReactNode;
};
const OrderedListItem = (props: OrderedListItemProps) => {
	return (
		<ListItem sx={{ display: "list-item" }}>
			<ListItemText>
				<Typography variant="title">{props.title}:</Typography>{" "}
				{props.children}
			</ListItemText>
		</ListItem>
	);
};

export default OrderedListItem;
