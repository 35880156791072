import React from "react";
import { Typography } from "@mui/material";

import StdView from "components/common/StdView";
import { Box } from "@mui/system";

import { Helmet } from "react-helmet";

const NotFound: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>
					404 not found! Bonus Lake - Bonuslake.dk - Din guide til de
					nyeste, gratis, odds og betting bonusser i Danmark. Der er
					bonusser til sider såsom, Tipwin, Betsson, betfair,
					LeoVegas, bet365, unibet og de er alle gratis - uden nogen
					form for betaling. Vi har sammenarbejde med de bedste online
					bookmakere og casinoer i Danmark. Desuden sammenarbejder vi
					med Betfair, Betsson, Bet365 og CASHPOINT. Så mangler du en
					overskuelig side til at finde de bedste; nyeste og mest
					eksklusive bonusser, så er du kommet til det rette sted.
					Bonuslake.dk - Din vejledning til gambling, casino og
					betting bonusser i Danmark.
				</title>
				<meta name="prerender-status-code" content="404"></meta>
			</Helmet>
			<StdView noSmallLogo index={-1}>
				<Box
					sx={{
						height: "50vh",
						textAlign: "center",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
					}}
				>
					<Typography variant="h1">404</Typography>

					<Typography variant="h2">Page not found</Typography>
				</Box>
			</StdView>
		</>
	);
};

export default NotFound;
