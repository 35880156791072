import React from "react";

import { Box, Typography, Divider } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { CenterColumnBox } from "../shared";

const CheckedList = ({
	title,
	content,
	color,
	icon,
}: {
	title: string;
	content: string[];
	color?: any;
	icon?: React.ReactNode;
}) => {
	return (
		<CenterColumnBox>
			<Typography textAlign={"center"} variant="h4">
				{title}
			</Typography>
			{content.map((item, index) => (
				<span key={index + "U"}>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							flexDirection: "row",
						}}
					>
						{icon != undefined ? icon : <CheckIcon color={color} />}
						<Typography variant="body1">{item}</Typography>
					</Box>
					<Divider />
				</span>
			))}
		</CenterColumnBox>
	);
};

export default CheckedList;
