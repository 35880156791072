import React from "react";

import { List, Link, Typography } from "@mui/material";
import OrderedListItem from "../components/OrderedListItem";

import StdView from "components/common/StdView";
import CenterRow from "components/common/CenterRow";
import { PageHandler } from "App";

import { Link as RouterLink } from "react-router-dom";

const linkColor = "#f0bdff";

const OmOs = () => {
	return (
		<>
			<StdView
				index={PageHandler.getIndexByName("OmOs")}
				noSmallLogo
				largeLogo
			>
				<CenterRow>
					<Typography
						sx={{ paddingBottom: "0.35rem" }}
						color="#FFFFFF"
						variant="h4"
					>
						Om Os - BonusLake Teamet
					</Typography>

					<Typography variant="title">
						Velkommen til BonusLake - Din Vejledning til Eksklusive
						Bonusser!
						<br />
					</Typography>
					<Typography variant="btekst">
						Vi er et passioneret team af gamblere og spilelskere,
						der har sat os for at skabe BonusLake som det ultimative
						værktøj til at opdage de mest attraktive bonusser på
						diverse betting sider.
						<br />
						Vi tror på, at spil skal være underholdende, givende og
						frem for alt sikkert.
						<br />
						<br />
					</Typography>
					<Typography variant="title">
						Vores Mission:
						<br />
					</Typography>
					<Typography variant="btekst">
						Vores mission er at gøre online spil mere gennemsigtigt
						og sjovt ved at give brugerne adgang til eksklusive
						bonusser og pålidelige oplysninger om betting sider.
						<br />
						Vi stræber efter at skabe en platform, der giver
						spillerne den bedste oplevelse, samtidig med at vi
						opfordrer til ansvarligt spil.
						<br />
						<br />
					</Typography>
					<Typography variant="title">Hvad Gør Os Unikke:</Typography>
					<List sx={{ listStyle: "decimal", pl: 2 }}>
						<OrderedListItem title="Erfaring">
							Vi har en lang historie inden for spilindustrien,
							hvilket giver os indsigt og forståelse for, hvad
							spillerne virkelig leder efter.
						</OrderedListItem>

						<OrderedListItem title="Ekspertise">
							Vores team består af erfarne gamblere,
							markedsanalytikere og teknologiske eksperter, der
							arbejder sammen for at levere den mest præcise og
							relevante information.
						</OrderedListItem>
						<OrderedListItem title="Ansvarlighed">
							Vi er forpligtede til at fremme ansvarligt spil og
							opfordrer vores brugere til at spille med måde og
							inden for deres betalingsmiddel.
						</OrderedListItem>
					</List>

					<Typography variant="title">
						Mød Teamet:
						<br />
					</Typography>
					<Typography variant="btekst">
						Malek Hassing-Hansen - Stifter og Spilelsker:{" "}
						<Link
							component={RouterLink}
							color={linkColor}
							to="https://www.instagram.com/malluki123/"
						>
							Instagram
						</Link>
						<br />
						Teknisk Ekspert:{" "}
						<Link
							component={RouterLink}
							color={linkColor}
							to="mailto:teknik@bonuslake.dk"
						>
							teknik@bonuslake.dk
						</Link>
						<br />
					</Typography>
					<br />

					<Typography variant="title">
						Din Feedback er Vigtig:
						<br />
					</Typography>
					<Typography variant="btekst">
						Vi sætter stor pris på feedback fra vores brugere. Det
						er jeres input, der hjælper os med at forbedre Bonuslake
						og skabe den bedste platform for alle gamblere.
						<br />
						Tak fordi du er en del af BonusLake-fællesskabet. Vi ser
						frem til at fortsætte med at levere spændende
						bonusmuligheder og en enestående spiloplevelse!
					</Typography>
				</CenterRow>
			</StdView>

			{/* <br /> */}
			{/* <Rofus /> */}
		</>
	);
};

export default OmOs;

//#region obsolete code

/*

			<NavBar s={1} />
			<Box sx={{}}>
				<Box
					sx={{
						marginRight: "auto",
						marginLeft: "auto",
						margin: "0 auto",
						overflow: "hidden",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<img
						style={{
							maxWidth: "40%",
							maxHeight: "20%",
						}}
						alt=""
						src="/bl.png"
					/>
				</Box>
			</Box>


<Box
	component="img"
	src="/assets/customcolor_logo_transparent_background.png"
	sx={{
		maxWidth: '50%',
	}}></Box>
<Typography variant="h6">
	Tak fordi du har valgt os som din guide for spænding og
	fantastiske bonusser!
</Typography>
Vores engagement i kvalitet og professionalisme afspejles i
					vores anbefalinger, hvor vi udforsker en verden af
					førsteklasses underholdning og attraktive bonusser.
					<br />
					Hos os finder du ikke kun spænding, men også en pålidelig
					indgang til eksklusive belønninger.
					<br />
					Vi har nøje udvalgt de bedste bonusser i hele Danmark for at
					forbedre din spiloplevelse.
					<br />
					Bonuslake.dk repræsenterer ikke kun en hjemmeside; det er
					din pålidelige guide til en bemærkelsesværdig spilrejse,
					hvor vi opretholder høje standarder inden for online
					gambling.
					<br />*/

//#endregion
